.page-bg {
  @apply pointer-events-none absolute -mt-3 flex h-96 w-full items-start justify-center md:h-118 lg:h-100;
}

.page-bg::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  @apply h-3/4 from-transparent md:h-1/2;
}

[data-theme='dark'] .page-bg::after {
  @apply bg-gradient-to-b to-[#0a1c2d];
}

[data-theme='light'] .page-bg::after {
  @apply bg-gradient-to-b to-[#EBECED];
}

.image-wrapper {
  clip-path: content-box;
  -webkit-clip-path: content-box;
  @media only screen and (min-width: 960px) {
    &:hover {
      .gamecard-overlay {
        opacity: 1;
        z-index: 20;
      }
      img {
        @apply transform-gpu;
      }
    }
  }
  @apply overflow-hidden rounded-2xl bg-bgr-default;
}

.bg-lateral-left {
  @apply absolute bottom-0 left-0 top-0 z-10 hidden  w-2/12 bg-gradient-to-l from-transparent md:block;
}
.bg-lateral-right {
  @apply absolute bottom-0 right-0 top-0 z-10 hidden  w-2/12 bg-gradient-to-r from-transparent  md:block;
}

.bg-transparent-bottom {
  @apply absolute bottom-0 left-0 right-0 z-10 hidden h-1/5 bg-gradient-to-b from-transparent md:block;
}

.bg-transparent-bottom-mobile {
  @apply absolute bottom-0 left-0 right-0 z-10  h-1/5 bg-gradient-to-b from-transparent;
}

[data-theme='dark'] .bg-lateral-left {
  --tw-gradient-to: #0a1c2d var(--tw-gradient-to-position);
}
[data-theme='dark'] .bg-transparent-bottom {
  --tw-gradient-to: #0a1c2d var(--tw-gradient-to-position);
}
[data-theme='dark'] .bg-lateral-right {
  --tw-gradient-to: #0a1c2d var(--tw-gradient-to-position);
}
[data-theme='light'] .bg-transparent-bottom {
  --tw-gradient-to: #ebeced var(--tw-gradient-to-position);
}
[data-theme='light'] .bg-lateral-left {
  --tw-gradient-to: #ebeced var(--tw-gradient-to-position);
}
[data-theme='light'] .bg-lateral-right {
  --tw-gradient-to: #ebeced var(--tw-gradient-to-position);
}
